export function Main() {
    return <>
    <div className="py-5 d-flex align-items-center" style={{
        backgroundImage: "url('./../img/innovation-campaign/pg1-bg.png')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh"
    }}>
        <div className="container-md text-center py-5 d-flex flex-column justify-content-center align-items-center h-100">
            {/* <img src="./../img/innovation-campaign/logos/pg1-logo.png" alt="" style={{width: "12.5rem"}} /> */}
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                {/* <h1 className="text-white text-uppercase montserrat">K.R.A.F.T Challenge 2025</h1> */}
                <img src="./../img/innovation-campaign/logos/kraft_logo.png" alt="" className="mb-5 d-none d-md-block" style={{width: "25rem"}} />
                <img src="./../img/innovation-campaign/logos/kraft_logo.png" alt="" className="mb-5 d-block d-md-none" style={{width: "12.5rem"}} />
                <h2 className="text-white text-uppercase mb-5 d-none d-md-block" style={{ letterSpacing: "0.5rem" }}>KPJ Redefining Aspirations for Tomorrow</h2>
                <h4 className="text-white text-uppercase mb-5 d-block d-md-none fw-bold" style={{ letterSpacing: "0.25rem" }}>KPJ Redefining Aspirations for Tomorrow</h4>
                <p className="text-white mb-5">
                    Ready to redefine healthcare with your ideas? The K.R.A.F.T. Challenge 2025 is more than just a competition, it is a movement powered by KPJ Healthcare’s commitment to innovation. Designed to ignite ingenuity, challenge boundaries and shape the future of healthcare, KRAFT provides a premier platform for visionaries, innovators and changemakers to drive clinical excellence, digitalisation and sustainability. If you have an idea that could redefine the future of healthcare, now is the time to bring it to life and be rewarded for it.
                </p>
                <button
                onClick={() => {
                    window.open("https://forms.gle/HfJt65wtV2AN4ZPt6", "blank")
                }}
                className="btn rounded-pill bg-white py-3 px-5 w-0 fw-bold">
                    Sign Up Now
                </button>
            </div>
        </div>
    </div>
    {/* <div className="py-5 d-flex align-items-center d-md-none" style={{
        backgroundImage: "url('./../img/innovation-campaign/pg1-bg-mobile.png')",
        height: "100vh",
        backgroundSize: "cover"
    }}>
        <div className="container-md text-center py-5 d-flex flex-column justify-content-center align-items-center h-100">
            <img src="./../img/innovation-campaign/logos/pg1-logo.png" alt="" style={{width: "12.5rem"}} />
            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <h1 className="text-white text-uppercase montserrat">K.R.A.F.T Challenge 2025</h1>
                <img src="./../img/innovation-campaign/logos/kraft_logo.png" alt="" className="pb-3" style={{width: "18.325rem"}} />
                <h3 className="text-white text-uppercase">KPJ Redefining Aspirations for Tomorrow</h3>
                <p className="text-white">
                    Ready to redefine healthcare with your ideas? The K.R.A.F.T. Challenge 2025 is more than just a competition, it is a movement powered by KPJ Healthcare’s commitment to innovation. Designed to ignite ingenuity, challenge boundaries and shape the future of healthcare, KRAFT provides a premier platform for visionaries, innovators and changemakers to drive clinical excellence, digitalization and sustainability. If you have an idea that could redefine the future of healthcare, now is the time to bring it to life and be rewarded for it.
                </p>
            </div>
            <div>
                <button
                onClick={() => {
                    window.open("https://forms.gle/HfJt65wtV2AN4ZPt6", "blank")
                }}
                className="btn rounded-pill bg-white py-3 px-5 w-0 fw-bold">
                    Sign Up Now
                </button>
            </div>
        </div>
    </div> */}
    </>
}