export function Categories() {
    return <div id="topics-and-themes">
    <div className="py-5 d-none d-md-block" style={{backgroundImage: "url('./../img/innovation-campaign/pg5-bg.png')", backgroundSize: "cover", position: "relative"}}>
        <div className="container-md text-center py-5">
            <div className="container-fluid" style={{
                position: "absolute",
                top: 0,
                left: 0
            }} >
                <div className="container-md d-flex justify-content-start">
                    <div className="d-flex bg-white" style={{
                        width: "fit-content",
                        mixBlendMode: "lighten"
                    }}>
                        <div className="p-3" style={{
                            backgroundColor: "black",
                            borderRadius: "0 3rem 0 0",
                        }}></div>
                        <div className="p-3" style={{
                            backgroundColor: "black",
                            borderRadius: "3rem 0 0 0",
                        }}></div>
                    </div>
                </div>
            </div>
            <h2 className="text-white text-uppercase">Topics and Themes</h2>
            <div className="pb-5"></div>
            <div className="row">
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-biotechnology.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Clinical Excellence</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-patientcare.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Patient Care</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-operational.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Operational Efficiency</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-comunnity.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Community Impact</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-sustainability.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Sustainability in Healthcare</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-technology.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Technology & Digitalisation</p></div>
                <div className="col-12 col-md">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-biotechnology.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Biotechnology & Pharmaceuticals</p></div>
            </div>
        </div>
    </div>
    <div className="py-5 d-md-none" style={{backgroundImage: "url('./../img/innovation-campaign/pg5-bg-mobile.png')", backgroundSize: "cover", position: "relative"}}>
        <div className="container-md text-center py-5">
            <div className="container-fluid" style={{
                position: "absolute",
                top: 0,
                left: 0
            }} >
                <div className="container-md d-flex justify-content-start">
                    <div className="d-flex bg-white" style={{
                        width: "fit-content",
                        mixBlendMode: "lighten"
                    }}>
                        <div className="p-3" style={{
                            backgroundColor: "black",
                            borderRadius: "0 3rem 0 0",
                        }}></div>
                        <div className="p-3" style={{
                            backgroundColor: "black",
                            borderRadius: "3rem 0 0 0",
                        }}></div>
                    </div>
                </div>
            </div>
            <h2 className="text-white text-uppercase">Topics and Themes</h2>
            <div className="pb-5"></div>
            <div className="row">
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-biotechnology.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Clinical Excellence</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-patientcare.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Patient Care</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-operational.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Operational Efficiency</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-comunnity.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Community Impact</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-sustainability.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Sustainability in Healthcare</p></div>
                <div className="col-6 col-md pb-3 pb-md-0">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-technology.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Technology & Digitalisation</p></div>
                <div className="col-12 col-md">
                    <img src="./../img/innovation-campaign/icons/pg5-symbol-biotechnology.png" alt="" style={{width: "5rem"}} className="mb-3" />
                    <p className="text-white">Biotechnology & Pharmaceuticals</p></div>
            </div>
        </div>
    </div>
    </div>
}