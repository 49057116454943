import EmblaCarousel from "../Components/EmblaCarousel"

const OPTIONS = { align: 'start' }
const SLIDE_COUNT = 6
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

export function JudgesAndMentors() {
    const judgesAndMentors = [
        {
            name: "Prof. Dr. Mohd Azmani Sahar",
            title: "Deputy Vice Chancellor\n(Research & Innovation)",
            organization: "KPJ Healthcare University",
            description: "Prof. Dr. Mohd Azmani Sahar, Deputy Vice Chancellor of Research and Innovation at KPJ Healthcare University, has a distinguished career in emergency medicine and has held academic positions at Universiti Sains Islam Malaysia (USIM). His extensive experience in medical research makes him a key leader in driving innovation in the medical community.",
            image: "dr azmani"
        },
        {
            name: "Nura Samsuddin",
            title: "Co-Founder",
            organization: "Supagene Asia",
            description: "Nura Samsuddin is the co-founder of Supagene Asia, making genetic-based health solutions accessible to all. With over a decade of experience in the biotechnology sector, Nura has been instrumental in bridging the gap between personalised health and genomics; ensuring accurate DNA testing for the Asian population.",
            image: "nura samsuddin"
        },
        {
            name: "Dr. Hyzan Mohd Yusof",
            title: "Chief Executive Officer",
            organization: "OSA Technology Sdn. Bhd.",
            description: "Dr. Hyzan Mohd Yusof is an orthopaedic surgeon at Sunway Medical Centre and CEO of OSA Technology Sdn. Bhd. He has served as an associate professor in the Orthopaedics Department at Hospital Universiti Kebangsaan Malaysia (HUKM) and is involved in humanitarian efforts worldwide, reflecting his dedication to improve global healthcare.",
            image: "dr hyzan"
        },
    ]
    return <div id="judges-and-mentors" className="py-5 bg-gold">
        <div className="container-md py-5 text-center">
            <h2 className="text-white text-uppercase">Judges & Mentors</h2>
            <div className="pb-5"></div>
            <p className="text-white">
                In a pitching session, this panel of esteemed judges and mentors will evaluate shortlisted projects based on innovation, impact, feasibility and presentation quality.
            </p>
            <div className="pb-5"></div>
            <EmblaCarousel slides={judgesAndMentors} options={OPTIONS} />
        </div>
    </div>
}