const socialMedias = [
    {
        image: "pg6-fb.png",
        url: "https://www.facebook.com/KPJHealthcareOfficial/"
    },
    {
        image: "pg6-linkedin.png",
        url: "https://www.linkedin.com/company/kpj-healthcare-bhd/"
    },
    {
        image: "pg6-insta.png",
        url: "https://www.instagram.com/kpjhealthcareberhadofficial/"
    },
    {
        image: "pg6-youtube.png",
        url: "https://www.youtube.com/@kpjhealthcaretv/featured/"
    }
]

export function ContactUs() {
    return <div id="contact-us" className="py-5 bg-black">
        <div className="container-md">
            <div className="row py-5">
                <div className="col-12 col-md text-center text-md-start">
                    <p className="text-white">Follow us on social media | KPJ Healthcare Berhad</p>
                    <div className="d-flex justify-content-center justify-content-md-start pb-5 pb-md-0" style={{gap: "1.25rem"}}>
                        {socialMedias.map(socialMedia => <a href={socialMedia.url} target="blank">
                            <img src={`./../img/innovation-campaign/icons/${socialMedia.image}`} alt="" style={{width: "2.5rem"}} />
                        </a>
                        )}
                        {/* <img src="./../img/innovation-campaign/icons/pg6-fb.png" alt="" style={{width: "2.5rem"}} /> */}
                        {/* <img src="./../img/innovation-campaign/icons/pg6-linkedin.png" alt="" style={{width: "2.5rem"}} />
                        <img src="./../img/innovation-campaign/icons/pg6-insta.png" alt="" style={{width: "2.5rem"}} />
                        <img src="./../img/innovation-campaign/icons/pg6-youtube.png" alt="" style={{width: "2.5rem"}} /> */}
                    </div>
                </div>
                <div className="col-12 col-md text-center text-md-end">
                    <button onClick={() => {
                        window.location = "mailto:kpjhs@kpjhealth.com.my"
                    }} className="btn bg-gold text-white px-5 py-3 rounded-pill fw-bold">Click here for more inquiry</button>
                </div>
            </div>
        </div>
    </div>
}