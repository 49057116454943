import React from 'react';

import './index.scss';

const BaseOurCollaborationCard = ({
    url,
    urlMobile,
    color = '#39B5B2',
    title,
    description,
    iconUrl,
}) => {
    if (!url) return <></>;
    return (
        <>
        <div
            className='h-100 w-100 d-flex align-items-end base-our-collaboration-card d-none d-md-block'
            style={{
                backgroundImage: `linear-gradient(transparent, transparent, ${color} 75%), url(${url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
            }}>
            <div
                className='base-our-collaboration-card__content'
                style={{ paddingTop: '9rem' }}>
                <img src={iconUrl} alt='icon-1' />
                <div>
                    <h4 className='text-white fw-bold'>{title}</h4>
                    <p className='flex-grow-1 text-white'>{description}</p>
                </div>
            </div>
        </div>
        <div className='d-md-none' style={{
            backgroundImage: `linear-gradient(90deg, transparent, transparent, ${color} 40%), url(${url})`,
            backgroundSize: 'contain',
            backgroundPosition: '',
            borderRadius: "32px"
        }}>
            <div className='row justify-content-center align-items-center'>
                <div className="col-4"></div>
                <div className="col-2 ps-0">
                    <img src={iconUrl} alt="" />
                </div>
                <div className='col-6 p-4 ps-0'>
                    <h6 className='text-white fw-bold' style={{ fontSize: "12px" }}>{title}</h6>
                    <p className='text-white m-0' style={{ fontSize: "9px", lineHeight: "12px" }}>{description}</p>
                </div>
            </div>
        </div>
        </>
    );
};

export default BaseOurCollaborationCard;
