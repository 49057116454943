import "./embla.css"
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

const EmblaCarousel = (props) => {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <div className="d-md-flex align-items-center">
    <div className="rounded-circle d-none d-md-block">
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} style={{color: "white"}} />
    </div>
    <section className="embla position-relative">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((index) => (
            <div className="embla__slide">
                <div className="">
                    <img src={`./../img/innovation-campaign/judges/${index.image}.png`} alt="" className="mb-3" />
                    <p className="text-white fw-bold" style={{ whiteSpace: "pre-line" }}>{index.name}<br/>{index.title}<br />{index.organization}</p>
                    <p className="text-white px-3"><small>{index.description}</small></p>

                </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between d-md-none" style={{scale: "0.25"}}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot border rounded-circle'.concat(
                index === selectedIndex ? ' embla__dot--selected bg-white' : ''
              )}
            />
          ))}
        </div>
      </div>

      {/* <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'bg-white embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div> */}
    </section>
    <div className="rounded-circle d-none d-md-block">
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} style={{color: "white"}} />
    </div>
    </div>
  )
}

export default EmblaCarousel
